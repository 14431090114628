import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import FeatureGrid from "components/ui/base/FeatureGrid";
import { SIGNUP_URL } from "constants/paths";

const CustomerSupportFeatures: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <FeatureGrid
      id={"platform-features"}
      title={t("components.ui.extended.CustomerSupportFeatures.title")}
      description={t(
        "components.ui.extended.CustomerSupportFeatures.description"
      )}
      button={{
        text: t("components.ui.extended.CustomerSupportFeatures.cta"),
        path: SIGNUP_URL,
      }}
      alternativeColors
      bigImages
      sections={[
        "ai",
        "chatflows",
        "help-center",
        "messenger",
        "reports",
        "help-desk",
      ].map((index) => {
        return {
          title: t(
            `components.ui.extended.CustomerSupportFeatures.${index}.title`
          ),
          description: t(
            `components.ui.extended.CustomerSupportFeatures.${index}.subtitle`
          ),
          image: {
            relativePath: t(
              `components.ui.extended.CustomerSupportFeatures.${index}.image`
            ),
            alt: t(
              `components.ui.extended.CustomerSupportFeatures.${index}.title`
            ),
          },
        };
      })}
    />
  );
};

export default CustomerSupportFeatures;
